export default {
  name: "Analytics",
  data() {
    return {
      innerPages: [
        {
          name: "Final Reported Accessions",
          path: "/analytics/final-reported-accessions",
          icon: "mdi-chart-bar",
        },
      ],
    };
  },
};
